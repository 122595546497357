(function($){
	$(function() {

		//searchfilter in the header
		$('#search form').submit(function(){
			var keyword = $(this).find('input[name="keyword"]').val();
			document.location = '/vacatures?keyword='+keyword;
			return false;
		});

		//vue component for the vacature overview
		if($('#job-overview-control').length > 0) {
			var app = new Vue({
				el: '#job-overview-control',
				mounted: function () {

					var self = this;

					//show element
					$('#job-overview-control').show();

					//check for the precense of a cur-page in the url
					var cur_page = self.getParam('page');
					if(cur_page !== '') {
						cur_page = '/'+cur_page;
					}

					this.getAllUrlParams();
					// Set filters based on the url
					this.setFilters();
					//set the current keyword
					// if(self.getParam('keyword') !== '') {
					// 	$('#keyword-filter').val(self.getParam('keyword'));
					// }

					//set the current concept filter
					// if(self.getParam('concept') !== '') {
					// 	$('#concept-filter #'+self.getParam('concept')).trigger('click').trigger('click');
					// }

					//fetch the current data
					self.fetchData('/vacature-items/ajax'+cur_page);

					//init the events for the paging
					$(document).on('click', '#job-overview-control .pagination .page-item .page-link', function(){
						var href = $(this).attr('href');

						//set the current page in the url
						self.setParam('page', href.split('/').pop());

						self.fetchData(href);
						return false;
					});

					//init the events for the paging$('#checkbox1').change(function() {
					$(document).on('change', '.filter input', function(){
						self.getFilter('werkgebied');
						self.getFilter('plaats');
						self.getFilter('dienstverband');

						self.fetchData('/vacature-items/ajax'+cur_page);
						return false;
					});

					//set the keyword timer
					var keywordTimer = null;

					//activate the keyword filters
					$('#keyword-filter input').on('input', function(){
						if(keywordTimer) {
							clearTimeout(keywordTimer);
						}

						keywordTimer = setTimeout(function(){
							self.fetchData('/vacature-items/ajax'+cur_page);
						}, 2000);
					});

					//activate the dropdown filters
					// $('.md-select').each(function (index, element) {
					// 	$(element).find('ul li').on('click', function () {
					// 		//fetch the data
					// 		self.fetchData('/portfolio-items/ajax'+cur_page);
					// 	});
					// });
				},
				data: {
					totalJobs: 0,
					curPage: 1,
					items: {},
					paging: {
						pages: []
					},
					filters: {
						keyword: '',
						werkgebied: [''],
						plaats: [''],
						dienstverband: ['']
					},
					limit: 12
				},
				// define methods under the `methods` object
				methods: {
					fetchData: function(url){
						var self = this;

						//fallback
						url = url || '/vacature-items/ajax';

						//collect the filter
						self.getFilter('werkgebied');
						self.getFilter('plaats');
						self.getFilter('dienstverband');
						self.getKeywordFilter();
						//show the loader
						$('body').addClass('show-loader');

						//get the data
						$.get(url, {
							limit: self.limit,
							keyword: self.filters.keyword,
							werkgebied: self.filters.werkgebied.join('|'),
							plaats: self.filters.plaats.join('|'),
							dienstverband: self.filters.dienstverband.join('|')
						}, function(data){
							//assing the items0
							data = JSON.parse(data);
							self.items = data.items;
							self.paging = data.paging;
							self.totalJobs = data.totalJobs;

							//hide the loader
							$('body').removeClass('show-loader');

							//no result, refresh in 3 seconds
							// if(data.items.length === 0) {
							// 	setTimeout(function(){
							//
							// 		//reset the filters
							// 		self.resetFilter('werkgebied');
							// 		self.resetFilter('plaats');
							// 		self.resetFilter('dienstverband');
							// 		//@todo reset keyword filter
							//
							// 		//fetch new data
							// 		self.fetchData(url, true);
							// 	}, 3000)
							// }
						});
					},
					resetFilter: function(type){
						var self = this;
						self.filters[type] = [];
						$('#'+type+'-filter input').each(function(){
							$(this).prop('checked', false);
						});

						self.setParam(type, '');
					},
					getFilter: function(type){
						var self = this;
						self.filters[type] = [];
						$('#'+type+'-filter input:checked').each(function(){
							self.filters[type].push($(this).val());
						});

						self.setParam(type, self.filters[type].join('|'));
					},
					setFilters: function() {
						var currentParams = this.params;
						var allFilters = $('.filter input');
						if (currentParams == undefined) return;
						allFilters.each(function() {
							var filterID = $(this).attr('id');
							var filterKeyValue = filterID.split('_');
							var filterKey = filterKeyValue[0];
							var filterValue = filterKeyValue[1];
							
							if (filterKey && filterValue && currentParams[filterKey] !== undefined) {
								// If the filter key exists in the URL params, set the filter
								if (Array.isArray(currentParams[filterKey])) {
									// If the filter value is an array, handle checkboxes or multi-selects
									if (currentParams[filterKey].includes(filterValue)) {
										$(this).prop('checked', true);
									}
								} else {
									// For single values, set the filter directly
									if (currentParams[filterKey] === filterValue) {
										$(this).prop('checked', true);
									}
								}
							}
						});
					},
					getKeywordFilter: function(){
						var self = this;
						self.filters['keyword'] = '';
						self.filters['keyword'] = $('#keyword-filter input').val();

						self.setParam('keyword', self.filters['keyword']);
					},
					getParam: function(key) {
						var query =  document.location.search.replace('?', '').split('&');
						var value = '';
						$.each(query, function(index, val) {
							var find = val.split('=');
							if(find[0] === key) {
								value = find[1];
							}
						});

						return value;
					},
					setParam: function(key, value) {
						var baseUrl = [location.protocol, '//', location.host, location.pathname].join(''),
							urlQueryString = document.location.search,
							newParam = key + '=' + value,
							params = '?' + newParam;
						// If the "search" string exists, then build params from it
						if (urlQueryString) {
							var updateRegex = new RegExp('([\?&])' + key + '[^&]*');
							var removeRegex = new RegExp('([\?&])' + key + '=[^&;]+[&;]?');

							if( typeof value === 'undefined' || value == null || value === '' ) { // Remove param if value is empty
								params = urlQueryString.replace(removeRegex, "$1");
								params = params.replace( /[&;]$/, "" );

							} else if (urlQueryString.match(updateRegex) !== null) { // If param exists already, update it
								params = urlQueryString.replace(updateRegex, "$1" + newParam);

							} else { // Otherwise, add it to end of query string
								params = urlQueryString + '&' + newParam;
							}
						}
						// no parameter was set so we don't need the question mark
						params = params === '?' ? '' : params;
						window.history.replaceState({}, "", baseUrl + params);
					},
					decoder: function(str) {
						var textArea = document.createElement('textarea');
						textArea.innerHTML = str;
						return textArea.value;
					},
					getAllUrlParams: function() {
						// Get the query string portion of the URL
						var queryString = window.location.search;
					
						// Create an empty object to store parameters
						var params = {};
					
						// If there are no parameters, return an empty object
						if (!queryString) {
							return params;
						}
					
						// Remove the leading question mark from the query string
						queryString = queryString.substring(1);
					
						// Split the query string into individual parameters
						var queryParams = queryString.split('&');
					
						// Loop through each parameter and parse it into key-value pairs
						queryParams.forEach(function(param) {
							var keyValue = param.split('=');
							var key = decodeURIComponent(keyValue[0]);
							var value = decodeURIComponent(keyValue[1] || '');
					
							// Add the key-value pair to the params object
							if (key) {
								// If the key already exists, convert the value to an array
								if (params[key]) {
									if (Array.isArray(params[key])) {
										params[key].push(value);
									} else {
										params[key] = [params[key], value];
									}
								} else {
									params[key] = value;
								}
							}
						});
					
						// Return the params object containing all parameters
						this.params = params;
					}
				}
			});

			$('#filter-form').submit(function(){
				app.fetchData();
				return false;
			});
		}
		
	});
})(jQuery);
