$(document).ready(function () {
    handleCarousel();
    gallery();
    handleTable();
    stickySidebarElement();

    $('.floating-label').each(function () {
        var inputField = $(this).find('input, textarea');

        if(inputField.val()){
            $(this).addClass('float');
        } else {
            $(this).removeClass('float');
        }

        $('input, textarea').on('change paste keyup focus', function (index, element) {
            if($(this).val() != ""){
                $(this).parent().addClass('float');
            } else {
                $(this).parent().removeClass('float');
            }
        });

    });

    $('input[type=file]').each(function (index, element) {
        var fileElement = $(element).parent();
        var fileNameDiv = '<div class="custom-upload-filename"></div>';
        $(fileNameDiv).appendTo(fileElement);

        $(element).on('change', function () {
            $(fileElement).find(".custom-upload-filename").empty();
            var filename = $(element).val().replace(/.*(\/|\\)/, '');
            fileElement.find(".custom-upload-filename").append(filename);
        });
    });


    var tag = document.createElement('script');

    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);


    $(".video-wrapper").each(function (index, element) {
        var videoWrapper = $(element);
        $(element).on('click', function (index, element) {
            var youtubeHash = $("#player").attr("data-youtube-hash");

            $(videoWrapper).toggleClass("pullout");
            $("body").toggleClass("fixed");

            if ( $('.video-wrapper').hasClass('pullout') ) {
                $('.video-close').removeClass('invisible');
                $('.video-close').addClass('visible');
            }

            if ( !$('.video-wrapper').hasClass('pullout') ) {
                $('.video-close').removeClass('visible');
                $('.video-close').addClass('invisible');
            }

            if($("#player").is("iframe")){
                $("#player").replaceWith("<div id='player' data-youtube-hash='"+ youtubeHash +"'></div>");
            } else {
                var player = new YT.Player('player', {
                    height: '390',
                    width: '640',
                    videoId: youtubeHash,
                    events: {
                        'onReady': onPlayerReady,
                        'onPlaying' : onPlaying,
                        'onclick' : onPlayerClick
                    }
                });
            }

        });

        function onPlayerClick(event){
            event.target.stopVideo();
        }

        function onPlayerReady(event) {
            event.target.playVideo();
        }

        function onPlaying(event) {
            event.target.stopVideo();
        }
    });

});

$(window).on("load", function() {

    handleFooter();
    stickySidebarElement();
    $(window).trigger('resize');
});

function handleCarousel() {
    $('.owl-carousel').owlCarousel({
        margin: 30,
        dots: false,
        responsive: {

            0: {
                items: 2,
                touchDrag: true,
                mouseDrag: false
            },

            768: {
                items: 4,
                mouseDrag: false
            },

            1200: {
                items: 6,
                mouseDrag: false
            },

            1600: {
                items: 7,
                mouseDrag: false
            },

            1900: {
                items: 9,
                mouseDrag: false
            }
        }
    });

    $('.team-member').on('click', function (index, element) {
        if(!$(this).parent().hasClass('test')) {
            $('.team-member').parent().removeClass('test');
            $(this).parent().addClass('test');
        } else {
            $(this).parent().removeClass('test');
        }
        // $(this).parent().toggleClass('test');
    });
}

function gallery() {

    $(".gallery").each(function(index,item) {

        $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile mfp-fade',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
                tCounter: '%curr% van %total%' // Markup for "1 of 7" counter
            },
            image: {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                titleSrc: function (item) {
                    return item.el.attr('title');
                }
            },
            removalDelay: 300
        });

        var galleryLength = $(item).find("a").length;

        if (galleryLength == 1) {
            $(item).addClass('one-image');
        } else if (galleryLength == 2) {
            $(item).addClass('two-image');
        } else if (galleryLength == 3) {
            $(item).addClass('three-image');
        } else if (galleryLength >= 4) {
            $(item).addClass('more');
        }
    });
}

window.onload = function() {
    progressiveImageLoader();
    progressiveBackgroundImage();
};

function progressiveBackgroundImage() {

    var placeholder = $(".bg-progressive");

    placeholder.each(function(index,element) {

        var imgUrlSmall = $(element).data('small');
        var imgUrlLarge = $(element).data('large');

        var imageWrapperSmall = $('<div class="background-image-small"></div>');
        var imageWrapperLarge = $('<div class="background-image-large"></div>');

        $(imageWrapperSmall).css("background-image","url(" + imgUrlSmall +")");
        $(imageWrapperLarge).css("background-image","url(" + imgUrlLarge +")");

        $(imageWrapperSmall).appendTo(element);
        $(imageWrapperLarge).appendTo(element);

        var img = new Image();
        img.src = imgUrlLarge;

        img.onload = function () {
            $(imageWrapperSmall).addClass('loaded');
            $(imageWrapperLarge).addClass('loaded');
        };
    });
}

function progressiveImageLoader() {
    var placeholder = $("figure.figure-progressive");

    placeholder.each(function(index,element) {

        //load small image
        var imageWrapperSmall = $('<img class="image-small">');
        imageWrapperSmall.attr('src', $(element).attr('data-small'));

        // //load large image
        var imageWrapperLarge = $('<img class="image-large">');
        imageWrapperLarge.attr('src', $(element).attr('data-large')).attr('srcset', $(element).attr('data-srcset')).attr('alt', $(element).attr('data-alt'));;

        imageWrapperSmall.appendTo(element);
        imageWrapperLarge.appendTo(element);

        var img = new Image();
        img.src = $(element).attr('data-large');

        //create image in DOM
        img.onload = function () {
            $(imageWrapperSmall).addClass('loaded');
            $(imageWrapperLarge).addClass('loaded');
        };

    });
}

function handleFooter() {
    $(window).resize(function() {
        var contentHeight = Math.round($("body").innerHeight());
        var windowWidth= $(window).innerWidth();
        var windowHeight = window.innerHeight;

        if((windowWidth >= 768) && ( contentHeight <= windowHeight)){
            $("footer").addClass("sticky");
        } else {
            $("footer").removeClass("sticky");
        }
    });
}

function handleTable() {
    $('table').addClass('table table-striped');
}

function stickySidebarElement() {
    $('.sticky-sidebar-element').each(function (index, element) {
        $(element).stickySidebar({
            topSpacing: 20,
            bottomSpacing: 20
        });
    });
}